import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import UniMarkets from 'components/Tables/UniMarkets'
import ProviderMarkets from 'components/Tables/ProviderMarkets'
import ProviderSelect from 'components/Inputs/ProviderSelect'
import CreateUniMarkets from 'components/Modals/CreateUniMarkets'
import Swal from 'sweetalert2'

import { getData, postData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import { set } from 'lodash'
import { validateViewCredentials } from 'utils/auth'

function Markets(props) {
  validateViewCredentials(props.routeInfo)

  const controller = new AbortController()

  const [providerId, setProviderId] = useState(0)
  const [updateUniMarkets, setUpdateUniMarkets] = useState(false)
  const [showCreateMarketModal, setCreateMarketsModal] = useState(false)
  const [uniSports, setUniSports] = useState([])
  const [uniGroups, setUniGroups] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [downloadcsv, setDownloadcsv] = useState(false)

  const updateSelectedProvider = pId => {
    setProviderId(pId)
  }

  const handleCreteMarketClick = () => {
    setCreateMarketsModal(true)
  }

  const handleSyncMarketClick = () => {
    Swal.fire({
      title: `You are about to sync the Markets into google sheet!<br><br> Are you sure you want to proceed?`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes, go for it!',
      denyButtonText: 'Cancel',
      icon: 'warning',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55',
      width: '50%'
    }).then(async result => {
      if (result.isConfirmed) {
        let response = await postData(`/api/reports/sync-google-sheet`, { table: 'market' })
        if (response != null) {
          Swal.fire('Process Completed!', `Synced markets successfully.`, 'success')
        } else {
          Swal.fire('Error!', `Something goes wrong while trying to sync the markets into google sheet.`, 'error')
        }
      }
    })
  }

  const handleCloseModal = updateUMarkets => {
    setCreateMarketsModal(false)
    if (updateUMarkets) {
      updateUniMarkets == true ? setUpdateUniMarkets(false) : setUpdateUniMarkets(true)
    }
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniSports(response.data)
    }
  }

  const fetchGroups = async controller => {
    let response = await getData('/api/unis/groups', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniGroups(response.data)
    }
  }

  const clickDownloadInfo = () => {
    setDownloadcsv(true)
  }

  const handleUniMarkets = data => {
    if (data.downloadcsv && data.downloadcsv == true) {
      setDisabled(false)
      setDownloadcsv(false)
    }
  }

  useEffect(() => {
    fetchSports(controller)
    fetchGroups(controller)

    return () => {
      controller.abort()
    }
  }, [])
  return (
    <>
      <Container fluid>
        <CreateUniMarkets showModal={showCreateMarketModal} signalCloseModal={handleCloseModal} sports={uniSports} groups={uniGroups} />
        <Row>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="4">
                    <Card.Title as="h4">Uni Markets</Card.Title>
                  </Col>
                  <Col md="8">
                    <Row>
                    <Col md="4" sm="12">
                      <Button className="create-region-btn" variant="success" onClick={handleSyncMarketClick}>
                        Sync Markets
                      </Button>
                    </Col>
                      <Col md="4" sm="12">
                        <Button className="create-region-btn" variant="success" onClick={handleCreteMarketClick}>
                          Create Uni Market
                        </Button>
                      </Col>
                      <Col md="4" sm="12">
                        <Button style={{ backgroundColor: 'rgb(44, 47, 45)' }} className="create-region-btn" disabled={disabled} variant="success" onClick={clickDownloadInfo}>
                          Export Data
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UniMarkets informParent={handleUniMarkets} downloadcsv={downloadcsv} forceUpdate={updateUniMarkets} sports={uniSports} groups={uniGroups} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Card.Title as="h4">Provider Markets</Card.Title>
                  </Col>
                  <Col md="6">
                    <ProviderSelect setParentProviderId={updateSelectedProvider} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <ProviderMarkets providerId={providerId} sports={uniSports} groups={uniGroups} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Markets
